import React from 'react'
import './App.scss'
import MainComponent from './components/MainComponent'

function App () {
  return (
    <div className="App">
      <MainComponent />
    </div>
  )
}

export default App
